import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";

export default class extends Component {
  render() {
    return (
      <div className="pricingReq">
        <Container>
          <Row>
            <Col></Col>
          </Row>
        </Container>
      </div>
    );
  }
}
